<template>
  <div class="bottomTar" v-if="isShow">
    <tabbar
      v-model="active"
      active-color="#343434"
      inactive-color="#343434"
      route
    >
      <tabbar-item
        v-for="(item, index) in bottomData"
        :key="index"
        replace
        :to="item.path"
        v-show="item.stats"
      >
        <span>{{ item.title }}</span>
        <img
          slot="icon"
          slot-scope="props"
          :src="props.active ? item.imgurlactive : item.imgurl"
        />
      </tabbar-item>
    </tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
import { isMiniEnv, isWeixin, isAliPay } from "@/utils/tools";
export default {
  components: {
    Tabbar,
    TabbarItem,
  },
  data() {
    return {
      active: 0,
      isShow: true,
      bottomData: [
        {
          title: "首页",
          imgurl: require("@static/vip/shoye.png"),
          imgurlactive: require("@static/vip/shoyexuanzhong.png"),
          clickType: "",
          stats: true,
          path: "/vip_mycenter",
        },
        {
          title: "扫码",
          imgurl: require("@static/vip/saoyisao.png"),
          imgurlactive: require("@static/vip/saoyisao.png"),
          clickType: "",
          stats: isWeixin() ? true : false,
          path: "/wxScan",
        },
        {
          title: "个人中心",
          imgurl: require("@static/vip/wode.png"),
          imgurlactive: require("@static/vip/wodexz.png"),
          clickType: "",
          stats: true,
          path: "/vippersonal",
        },
      ],
    };
  },
  mounted() {
    this.isShowBar();
  },
  methods: {
    async isShowBar() {
      try {
        let ali_status = await isAliPay();
        if (isMiniEnv()) this.isShow = false;
        else if(ali_status) this.isShow = false;
        console.log('isShow'+this.isShow)
      } catch (error) {
        console.log('h5, no ali')
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bottomTar {
  width: 100%;
  height: 50px;
  display: flex;
  position: fixed;
  align-items: center;
  padding: 10px 0 6px 0;
  // border-top: 0.5px solid #d8d8d8;
  background-color: #fff;
  bottom: 0;
  left: 0;
  z-index: 9999;
}
</style>
