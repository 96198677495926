<template>
  <div class="vip-all">
    <transition name="van-fade">
      <router-view></router-view>
    </transition>
    <bottomTar></bottomTar>
  </div>
</template>
<script>
import bottomTar from "./child/bottomTar"
// import BottomTar from "@/views/all/tabbar";
export default {
  components: { bottomTar },
  data() {
    return {
      bottomData: [
        {
          title :"会员卡",
          ico: "iconfont iconhuiyuan2",
          clickType: "",
          path: "/viphome"
        },
        {
          title :"积分商城",
          ico: "iconfont icondaojushangcheng",
          clickType: "",
          path: "/integralshop"
        },
        {
          title :"油站",
          ico: "iconfont iconjiayoufuwu",
          clickType: "",
          path: "/vipShopList"
        },      
        {
          title: "个人中心",
          ico: "iconfont icongeren1",
          clickType: "",
          path: "/vippersonal"
        }
      ],
    }
  }
}
</script>
<style lang="scss" scoped>
.vip-all {
  width: 100%;
  height: 100vh;
}
</style>